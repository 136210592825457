






import Vue from 'vue';
export default Vue.extend({
  components: {
    BlankView: () => import('./View.vue')
  }
});
